import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpEvent,
    HttpEventType,
    HttpHeaders,
    HttpRequest,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { UtilService } from './util.service';
import { ConfigService } from './config.service';
import { ToasterService } from './toaster.service';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AjaxService {
    public loadingArray: {} = {};

    public transferProgress: number = null;

    public constructor(
        private http: HttpClient,
        private readonly locationStrategy: LocationStrategy,
        private util: UtilService,
        private config: ConfigService,
        private toaster: ToasterService,
        private storage: StorageService
    ) {}

    /*
     * get
     *
     * Will perform a http get to the given target
     */
    public get(url: string, loadingTarget?: string): Promise<any> {
        return this.handleRequest(
            this.http.get(this.config.apiUrl + url, {
                headers: this.getHeaders(),
            }),
            loadingTarget
        );
    }

    /**
     * Will perform an http GET to the given URL without decoding JSON
     *
     * @author    Mike van Os <mike@safira.nl>
     */
    public getRaw(url: string, loadingTarget?: string): Promise<any> {
        return this.handleRequest(
            this.http.get(this.config.apiUrl + url, {
                headers: this.getHeaders(),
                responseType: 'blob',
                observe: 'response',
            }),
            loadingTarget
        );
    }

    /**
     * Will perform a http GET to the given URL without decoding JSON
     *
     * @author    Ruben Janssens <ruben@safira.nl>
     */
    public postRaw(url: string, data: any, loadingTarget?: string) {
        return this.handleRequest(
            this.http.post(this.config.apiUrl + url, data, {
                headers: this.getHeaders(),
                responseType: 'blob',
                observe: 'response',
            }),
            loadingTarget
        );
    }

    /*
     * post
     *
     * Will perform a http post to the given target
     */
    public post(url: string, data: any, loadingTarget?: string): Promise<any> {
        return this.handleRequest(
            this.http.post(this.config.apiUrl + url, data, {
                headers: this.getHeaders(),
            }),
            loadingTarget
        );
    }

    /*
     * delete
     *
     * Will perform a http delete to the given target
     */
    public delete(url: string, loadingTarget?: string): Promise<any> {
        return this.handleRequest(
            this.http.delete(this.config.apiUrl + url, {
                headers: this.getHeaders(),
            }),
            loadingTarget
        );
    }

    /*
     * delete
     *
     * Will perform a http delete to the given target
     */
    public put(url: string, data: any, loadingTarget?: string): Promise<any> {
        return this.handleRequest(
            this.http.put(this.config.apiUrl + url, data, {
                headers: this.getHeaders(),
            }),
            loadingTarget
        );
    }

    /*
     * upload
     *
     * Will perform a http upload to the given target
     */
    public upload(url: string, file: File, name: string, data?: any, loadingTarget?: string): Promise<any> {
        const formData: FormData = new FormData();

        if (data != null) {
            const keys: Array<string> = Object.keys(data);
            const values: Array<any> = keys.map((e: string) => data[e]); // IE11 fix

            for (let i: number = 0; i < keys.length; i++) {
                formData.append(keys[i], values[i]);
            }
        }

        formData.append(name, file, file.name);

        return this.post(url, formData, loadingTarget);
    }

    /*
     * showLoading
     *
     * Will show a loading div inside the target
     */
    public showLoading(target: string): void {
        if (target != null) {
            if (this.util.getElement(target) !== undefined && this.util.getElement(target) !== null) {
                this.loadingArray[target] = 1;

                var element: HTMLElement = this.util.getElement(target);
                var newElement: HTMLElement = this.util.createElement('div', 'loader-wrapper', '');
                element.appendChild(newElement);

                var loader: HTMLElement = this.util.getChild(element, '.loader-wrapper');
                var newElement: HTMLElement = this.util.createElement('div', '', '');
                loader.appendChild(newElement);
            }
        }
    }

    /*
     * removeLoading
     *
     * Will remove the loading div inside the target
     */
    public removeLoading(target: string): void {
        if (target != null) {
            if (this.util.getElement(target) !== undefined && this.util.getElement(target) !== null) {
                delete this.loadingArray[target];
                let $targetElement: HTMLElement = this.util.getElement(target);
                $targetElement = this.util.getChild($targetElement, 'loader-wrapper');
                if (typeof $targetElement !== 'undefined') {
                    $targetElement.remove();
                }
            }
        }
    }

    private handleRequest(observable: Observable<any>, loadingTarget?: string, toasterMessage?: string) {
        this.showLoading(loadingTarget);
        return new Promise((resolve, reject) => {
            observable.toPromise().then(
                (result) => {
                    this.removeLoading(loadingTarget);
                    if (toasterMessage) {
                        this.toaster.success(toasterMessage);
                    }
                    if (result.errors) {
                        for (const error of result.errors) {
                            this.toaster.error(error);
                        }
                    }
                    resolve(result.data);
                },
                (result) => {
                    this.removeLoading(loadingTarget);
                    let message = result.message;
                    let status = result.error.returnCode;
                    if (status == undefined && result.status != undefined) {
                        status = result.status;
                    }
                    message = 'Er is een fout opgetreden. Foutcode: ' + status + ' - ' + message;
                    if (status === 0) {
                        message = 'API niet bereikbaar';
                    }
                    this.toaster.error(message);

                    // If the login is invalid, force someone to login anew.
                    if (result instanceof HttpErrorResponse) {
                        if (result.status === 403 && result.error.startsWith('Invalid ')) {
                            localStorage.removeItem('authToken');
                            window.location.href =
                                location.origin + this.locationStrategy.getBaseHref() + 'login';
                            return;
                        }
                        if (typeof result.error === 'object' && (result.error?.DB?.errors.length ?? false)) {
                            for (const error of result.error.DB.errors) {
                                console.error(error.message);
                                console.error(error.sql);
                                console.error(error.backtrace);
                            }
                            return;
                        }
                    }
                    reject(result);
                }
            );
        });
    }

    /*
     * getHeaders
     *
     * Will set the custom headers for a request
     */
    private getHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers
            .append('API-KEY', this.storage.getStorage('apiKey'))
            .append('AUTH-TOKEN', this.storage.getStorage('authToken'))
            .append('LANGUAGE', this.storage.getStorage('currentLanguage'))
            .append('2FA-TOKEN', localStorage.getItem('2fa_app') ?? '');

        return headers;
    }

    /**
     * downloadZip
     *
     * downloads a zip by httpClient request, includes transfer progress
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      url    string
     * @returns    Promise<any> //params blob, type and filename
     */
    public downloadZip(url: string): Promise<any> {
        const req = new HttpRequest('GET', this.config.apiUrl + url, {
            headers: this.getHeaders(),
            reportProgress: true,
            responseType: 'blob',
        });

        let totalSize: number = 0;
        let filename: string = '';
        let type: string = '';

        return new Promise(async (resolve) => {
            this.http.request(req).subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.ResponseHeader:
                        totalSize = parseInt(event.headers.get('Content-Size'));
                        filename = event.headers
                            .get('Content-Disposition')
                            .split(';')[1]
                            .split('filename*')[1]
                            .split('=')[1]
                            .trim()
                            .replace("UTF-8''", '');
                        type = event.headers.get('Content-Type');
                        break;
                    case HttpEventType.DownloadProgress:
                        this.transferProgress = Math.ceil((100 * event.loaded) / totalSize);
                        break;
                    case HttpEventType.Response:
                        let result: object = { blob: event.body, type: type, filename: filename };
                        resolve(result);
                        break;
                }
            });
        });
    }
}
